// 引用 Mock
const Mock = require('mockjs')
//全国 100000
export const userData = Mock.mock(`/data/list?id=100000`, "get", (req) => {
    console.log(req);
    return {
        //浙江麒典信息科技有限公司 
        //浙江中峪农业发展有限责任公司 https://www.amap.com/service/regeo?longitude=120.455038&latitude=30.110526
        //四川中峪商贸服务有限责任公司 https://www.amap.com/service/regeo?longitude=105.414307&latitude=28.215201
        //丽水金生投资管理有限责任公司 https://www.amap.com/service/regeo?longitude=105.688263&latitude=27.862136
        //浙江中峪日用品交易市场有限公司 https://www.amap.com/service/regeo?longitude=120.486645&latitude=30.110855
        //四川中峪拍卖交易服务有限责任公司 https://www.amap.com/service/regeo?longitude=105.501628&latitude=28.909885
        //绍兴中峪职业技能培训学校有限公司 https://www.amap.com/service/regeo?longitude=120.50114&latitude=30.090647
        "list": [
            {
                "id": 6,
                "name": "浙江中峪农业发展有限责任公司",
                "value": [120.455038, 30.110526, 1],
                "adminCode": 330600,
                "city": "浙江省绍兴市柯桥区柯桥街道绍兴世界贸易中心(南区)6幢108-01室",
                "itemStyle": { color: '#ffc000', borderColor: '#ffc000' },
                "companyDetails": '浙江中峪农业发展有限责任公司（简称“中峪农业”），秉持以绿水青山就是金山银山的发展理念，传承发展中医药产业为己任的宗旨，以数字化改革和乡村振兴为初心，致力于在农业领域推动创新数字化发展，专注于道地药材从源头孵化与保护产业生态，带动地方就业、加工...',
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "hangzhou_test"
            },
            {
                'id': 7,
                "name": "浙江麒典信息科技有限公司",
                "value": [120.500635, 30.090625, 1],
                "companyDetails": "麒典信息科技是一家集大数据服务、核心技术研发、技术咨询服务、区块链技术应用、软件开发等科技研发公司，为集团产业链数字化运作提供强大技术输出，肩负集团产业链大数据信息储存输送和科技数字化运作重任，同时为助力地方产业数字化发展提供强大技术支撑，承载...",
                "city": "浙江省绍兴市柯桥区柯桥中洲大厦0913室",
                "adminCode": 330200,
                "itemStyle": { color: '#ff7300', borderColor: '#ff7300' },
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "ningbo_test"
            },
            {
                "id": 1,
                "name": "浙江中峪日用品交易市场有限公司",
                "value": [120.486645, 30.110855, 1],
                "adminCode": 330600,
                "itemStyle": { color: '#f05631', borderColor: '#f05631' },
                "companyDetails": "中峪日用品交易市场均为中峪集团十大闭环产业之一，具有多行业背景和资源优势，整合融通了多品种商品资源、资产数字化运作、仓储物流、资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，帮助中小企业实现去库存、优化产能拉动内需...",
                "city": "浙江省绍兴市柯桥区柯桥街道绍兴世界贸易中心(南区)6幢108-2室",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "wenzhou_test"
            },
            {
                "id": 3,
                "name": "绍兴中峪职业技能培训学校有限公司",
                "value": [120.50114, 30.090647, 1],
                "adminCode": 330600,
                "itemStyle": { color: '#ffc000', borderColor: '#ffc000' },
                "companyDetails": "中峪职业培训学校是一家结合国家职业教育体系改革指导政策，肩负起特色职业技能培育，为中峪产业培育人才，给社会输送专业人才，同时解决就业再就业问题，为地方职业教育发展提供助力，为养老和文化产业发展提供助力。",
                "city": "浙江省绍兴市柯桥区柯桥街道世界贸易中心（南区）16-0207室",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "shaoxing_test"
            },
            {
                "id": 101,
                "name": "中峪道地药材平顶山仿野生白术种植基地",
                "value": [121.020489, 29.385847, 1],
                "adminCode": 330900,
                "itemStyle": { color: '#ff7300', borderColor: '#ff7300' },
                "type": 0,
                "city": "浙江新昌县沃洲镇黄坛村",
                "companyDetails": "新昌县沃洲镇黄坛村平顶山项目基地,隶属于浙江中峪道地药材有限公司绍兴市级区域范围内。成片种植浙八味之首白术,土地规模整村推进完整闭环100亩,一年生的道地白术种苗种植时间2023年12月至2024年2月,收成时间2025年10月至2026年1月。拟定生长周期为两年期,届时...",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "zhoushan_test"
            },
            {
                "id": 4,
                "name": "丽水金生投资管理有限责任公司",
                "value": [105.688263, 27.862136, 1],
                "adminCode": 510500,
                "itemStyle": { color: '#f05631', borderColor: '#f05631' },
                "companyDetails": "投资管理公司不仅仅为各产业提供金融助力，更是为了集团进一步发展服务，在集团稳健发展的基础上，进行分层级的资本良性运作，为社会创造更多的财富，为共同富裕助力。",
                "city": "四川省叙永县龙凤镇街村（原龙凤计划生育服务站）3层",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "taizhou_test"
            },
            {
                "id": 5,
                "name": "四川中峪商贸服务有限责任公司",
                "value": [105.414307, 28.215201, 1],
                "adminCode": 510500,
                "itemStyle": { color: '#ffc000', borderColor: '#ffc000' },
                "city": "四川省叙永县龙凤镇街村（原龙凤计划生育服务站）1层",
                "companyDetails": "中峪商贸为四川省泸州市地方政府重点招商引资项目，是一家承载孵化落地集团全产业链使命的运营公司，以其独特商业运营架构和科学创新的市场培育体系，助推地方中小企业产品流通，促进地方区域经济发展，优化商品流通渠道。协同中峪拍交所为集团产业导入高粘度市场流...",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "chengdu_test"
            },
            {
                "id": 2,
                "name": "四川中峪拍卖交易服务有限责任公司",
                "value": [105.501628, 28.909885, 1],
                "adminCode": 510500,
                "city": "四川省泸州市龙马潭区自贸区川南临港片区临港大道二段10号",
                "itemStyle": { color: '#ff7300', borderColor: '#ff7300' },
                "companyDetails": "四川中峪拍卖交易服务有限责任公司简称“中峪数交”，为中峪集团生态内循环闭环产业链之一，具有多行业背景和资源优势，整合融通了多品种商品资源、数字化拍卖服务、仓储物流资源和资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合...",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "chengdu_test"
            },
        ],
        code: 200,
        message: 'ok',
    }
});
//浙江 330000
export const userDatazhejiang = Mock.mock(`/data/list?id=330000`, "get", (req) => {
    return {
        "list": [
            {
                "id": 6,
                "name": "浙江中峪农业发展有限责任公司",
                "value": [120.455038, 30.110526, 1],
                "adminCode": 330600,
                "city": "浙江省绍兴市柯桥区柯桥街道绍兴世界贸易中心(南区)6幢108-01室",
                "itemStyle": { color: '#ffc000', borderColor: '#ffc000' },
                "companyDetails": '浙江中峪农业发展有限责任公司（简称“中峪农业”），秉持以绿水青山就是金山银山的发展理念，传承发展中医药产业为己任的宗旨，以数字化改革和乡村振兴为初心，致力于在农业领域推动创新数字化发展，专注于道地药材从源头孵化与保护产业生态，带动地方就业、加工...',
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "hangzhou_test"
            },
            {
                'id': 7,
                "name": "浙江麒典信息科技有限公司",
                "value": [120.500635, 30.090625, 1],
                "adminCode": 330200,
                "itemStyle": { color: '#ff7300', borderColor: '#ff7300' },
                "city": "浙江省绍兴市柯桥区柯桥中洲大厦0913室",
                "companyDetails": "麒典信息科技是一家集大数据服务、核心技术研发、技术咨询服务、区块链技术应用、软件开发等科技研发公司，为集团产业链数字化运作提供强大技术输出，肩负集团产业链大数据信息储存输送和科技数字化运作重任，同时为助力地方产业数字化发展提供强大技术支撑，承载...",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "ningbo_test"
            },
            {
                "id": 1,
                "name": "浙江中峪日用品交易市场有限公司",
                "value": [120.486645, 30.110855, 1],
                "adminCode": 330300,
                "city": "浙江省绍兴市柯桥区柯桥街道绍兴世界贸易中心(南区)6幢108-2室",
                "itemStyle": { color: '#f05631', borderColor: '#f05631' },
                "companyDetails": "中峪日用品交易市场均为中峪集团十大闭环产业之一，具有多行业背景和资源优势，整合融通了多品种商品资源、资产数字化运作、仓储物流、资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，帮助中小企业实现去库存、优化产能拉动内需...",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "wenzhou_test"
            },
            {
                "id": 3,
                "name": "绍兴中峪职业技能培训学校有限公司",
                "value": [120.50114, 30.090647, 1],
                "adminCode": 330600,
                "city": "浙江省绍兴市柯桥区柯桥街道世界贸易中心（南区）16-0207室",
                "itemStyle": { color: '#ffc000', borderColor: '#ffc000' },
                "companyDetails": "中峪职业培训学校是一家结合国家职业教育体系改革指导政策，肩负起特色职业技能培育，为中峪产业培育人才，给社会输送专业人才，同时解决就业再就业问题，为地方职业教育发展提供助力，为养老和文化产业发展提供助力。",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "shaoxing_test"
            },
            {
                "id": 'type',
                "name": "中峪道地药材平顶山仿野生白术种植基地",
                "value": [121.020489, 29.385847, 1],
                "adminCode": 330900,
                "itemStyle": { color: '#ff7300', borderColor: '#ff7300' },
                "type": 0,
                "companyDetails": "新昌县沃洲镇黄坛村平顶山项目基地,隶属于浙江中峪道地药材有限公司绍兴市级区域范围内。成片种植浙八味之首白术,土地规模整村推进完整闭环100亩,一年生的道地白术种苗种植时间2023年12月至2024年2月,收成时间2025年10月至2026年1月。拟定生长周期为两年期,届时...",
                "city": "浙江新昌县沃洲镇黄坛村",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "zhoushan_test"
            },
        ],
        code: 200,
        message: 'ok',
    }
});
//四川 510000
export const userDatasichuan = Mock.mock(`/data/list?id=510000`, "get", (req) => {
    return {
        // 属性 list 的值是一个数组，随机生成 1 到 10 个元素
        "list": [
            {
                "id": 4,
                "name": "丽水金生投资管理有限责任公司",
                "value": [105.688263, 27.862136, 1],
                "adminCode": 510500,
                "city": "四川省叙永县龙凤镇街村（原龙凤计划生育服务站）3层",
                "itemStyle": { color: '#f05631', borderColor: '#f05631' },
                "companyDetails": "投资管理公司不仅仅为各产业提供金融助力，更是为了集团进一步发展服务，在集团稳健发展的基础上，进行分层级的资本良性运作，为社会创造更多的财富，为共同富裕助力。",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "taizhou_test"
            },
            {
                "id": 5,
                "name": "四川中峪商贸服务有限责任公司",
                "value": [105.414307, 28.215201, 1],
                "adminCode": 510500,
                "itemStyle": { color: '#ffc000', borderColor: '#ffc000' },
                "city": "四川省叙永县龙凤镇街村（原龙凤计划生育服务站）1层",
                "companyDetails": "中峪商贸为四川省泸州市地方政府重点招商引资项目，是一家承载孵化落地集团全产业链使命的运营公司，以其独特商业运营架构和科学创新的市场培育体系，助推地方中小企业产品流通，促进地方区域经济发展，优化商品流通渠道。协同中峪拍交所为集团产业导入高粘度市场流...",

                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "chengdu_test"
            },
            {
                "id": 2,
                "name": "四川中峪拍卖交易服务有限责任公司",
                "value": [105.501628, 28.909885, 1],
                "adminCode": 510500,
                "city": "四川省泸州市龙马潭区自贸区川南临港片区临港大道二段10号",
                "number": '400-185-3168',
                "itemStyle": { color: '#ff7300', borderColor: '#ff7300' },
                "companyDetails": "四川中峪拍卖交易服务有限责任公司简称“中峪数交”，为中峪集团生态内循环闭环产业链之一，具有多行业背景和资源优势，整合融通了多品种商品资源、数字化拍卖服务、仓储物流资源和资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合...",
                "label": {
                    "show": false
                },
                "engineer": "chengdu_test"
            },
        ],
        code: 200,
        message: 'ok',
    }
});
//泸州 510100
export const userDataleshan = Mock.mock(`/data/list?id=510500`, "get", (req) => {
    return {
        // 属性 list 的值是一个数组，随机生成 1 到 10 个元素
        "list": [
            {
                "id": 2,
                "name": "四川中峪拍卖交易服务有限责任公司",
                "value": [105.501628, 28.909885, 1],
                "adminCode": 510500,
                "city": "四川省泸州市龙马潭区自贸区川南临港片区临港大道二段10号",
                "itemStyle": { color: '#ff7300', borderColor: '#ff7300' },
                "companyDetails": "四川中峪拍卖交易服务有限责任公司简称“中峪数交”，为中峪集团生态内循环闭环产业链之一，具有多行业背景和资源优势，整合融通了多品种商品资源、数字化拍卖服务、仓储物流资源和资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合...",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "chengdu_test"
            },
            {
                "id": 4,
                "name": "丽水金生投资管理有限责任公司",
                "value": [105.688263, 27.862136, 1],
                "adminCode": 510500,
                "city": "四川省叙永县龙凤镇街村（原龙凤计划生育服务站）3层",
                "itemStyle": { color: '#f05631', borderColor: '#f05631' },
                "companyDetails": "投资管理公司不仅仅为各产业提供金融助力，更是为了集团进一步发展服务，在集团稳健发展的基础上，进行分层级的资本良性运作，为社会创造更多的财富，为共同富裕助力。",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "taizhou_test"
            },
            {
                "id": 5,
                "name": "四川中峪商贸服务有限责任公司",
                "value": [105.414307, 28.215201, 1],
                "adminCode": 510500,
                "itemStyle": { color: '#ffc000', borderColor: '#ffc000' },
                "companyDetails": "中峪商贸为四川省泸州市地方政府重点招商引资项目，是一家承载孵化落地集团全产业链使命的运营公司，以其独特商业运营架构和科学创新的市场培育体系，助推地方中小企业产品流通，促进地方区域经济发展，优化商品流通渠道。协同中峪拍交所为集团产业导入高粘度市场流...",

                "city": "四川省叙永县龙凤镇街村（原龙凤计划生育服务站）1层",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "chengdu_test"
            },
        ],
        code: 200,
        message: 'ok',
    }
});
//绍兴市 330600
export const userDatasx = Mock.mock(`/data/list?id=330600`, "get", (req) => {
    return {
        "list": [
            {
                "id": 6,
                "name": "浙江中峪农业发展有限责任公司",
                "value": [120.455038, 30.110526, 1],
                "adminCode": 330603,
                "city": "浙江省绍兴市柯桥区柯桥街道绍兴世界贸易中心(南区)6幢108-01室",
                "itemStyle": { color: '#ffc000', borderColor: '#ffc000' },
                "companyDetails": '浙江中峪农业发展有限责任公司（简称“中峪农业”），秉持以绿水青山就是金山银山的发展理念，传承发展中医药产业为己任的宗旨，以数字化改革和乡村振兴为初心，致力于在农业领域推动创新数字化发展，专注于道地药材从源头孵化与保护产业生态，带动地方就业、加工...',

                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "hangzhou_test"
            },
            {
                "id": 1,
                "name": "浙江中峪日用品交易市场有限公司",
                "value": [120.486645, 30.110855, 1],
                "adminCode": 330603,
                "city": "浙江省绍兴市柯桥区柯桥街道绍兴世界贸易中心(南区)6幢108-2室",
                "itemStyle": { color: '#f05631', borderColor: '#f05631' },
                "companyDetails": "中峪日用品交易市场均为中峪集团十大闭环产业之一，具有多行业背景和资源优势，整合融通了多品种商品资源、资产数字化运作、仓储物流、资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，帮助中小企业实现去库存、优化产能拉动内需...",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "wenzhou_test"
            },
            {
                "id": 3,
                "name": "绍兴中峪职业技能培训学校有限公司",
                "value": [120.50114, 30.090647, 1],
                "adminCode": 330603,
                "city": "浙江省绍兴市柯桥区柯桥街道世界贸易中心（南区）16-0207室",
                "itemStyle": { color: '#ffc000', borderColor: '#ffc000' },
                "companyDetails": "中峪职业培训学校是一家结合国家职业教育体系改革指导政策，肩负起特色职业技能培育，为中峪产业培育人才，给社会输送专业人才，同时解决就业再就业问题，为地方职业教育发展提供助力，为养老和文化产业发展提供助力。",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "shaoxing_test"
            },
            {
                "id": 'type',
                "name": "中峪道地药材平顶山仿野生白术种植基地",
                "value": [121.020489, 29.385847, 1],
                "adminCode": 330900,
                "itemStyle": { color: '#ff7300', borderColor: '#ff7300' },
                "type": 0,
                "companyDetails": "新昌县沃洲镇黄坛村平顶山项目基地,隶属于浙江中峪道地药材有限公司绍兴市级区域范围内。成片种植浙八味之首白术,土地规模整村推进完整闭环100亩,一年生的道地白术种苗种植时间2023年12月至2024年2月,收成时间2025年10月至2026年1月。拟定生长周期为两年期,届时...",
                "city": "浙江新昌县沃洲镇黄坛村",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "zhoushan_test"
            },
        ],
        code: 200,
        message: 'ok',
    }
});
//柯桥 330603 
export const userDatakq = Mock.mock(`/data/list?id=330603`, "get", (req) => {
    return {
        "list": [
            {
                "id": 6,
                "name": "浙江中峪农业发展有限责任公司",
                "value": [120.455038, 30.110526, 1],
                "adminCode": 330603,
                "city": "浙江省绍兴市柯桥区柯桥街道绍兴世界贸易中心(南区)6幢108-01室",
                "number": '400-185-3168',
                "cityCode": 330603,
                "itemStyle": { color: '#ffc000', borderColor: '#ffc000' },
                "companyDetails": '浙江中峪农业发展有限责任公司（简称“中峪农业”），秉持以绿水青山就是金山银山的发展理念，传承发展中医药产业为己任的宗旨，以数字化改革和乡村振兴为初心，致力于在农业领域推动创新数字化发展，专注于道地药材从源头孵化与保护产业生态，带动地方就业、加工...',
                "label": {
                    "show": false
                },
                "engineer": "hangzhou_test"
            },
            {
                'id': 7,
                "name": "浙江麒典信息科技有限公司",
                "value": [120.500635, 30.090625, 1],
                "adminCode": 330200,
                "city": "浙江省绍兴市柯桥区柯桥中洲大厦0913室",
                "itemStyle": { color: '#ff7300', borderColor: '#ff7300' },
                "companyDetails": "麒典信息科技是一家集大数据服务、核心技术研发、技术咨询服务、区块链技术应用、软件开发等科技研发公司，为集团产业链数字化运作提供强大技术输出，肩负集团产业链大数据信息储存输送和科技数字化运作重任，同时为助力地方产业数字化发展提供强大技术支撑，承载...",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "ningbo_test"
            },
            {
                "id": 1,
                "name": "浙江中峪日用品交易市场有限公司",
                "value": [120.486645, 30.110855, 1],
                "adminCode": 330603,
                "city": "浙江省绍兴市柯桥区柯桥街道绍兴世界贸易中心(南区)6幢108-2室",
                "itemStyle": { color: '#f05631', borderColor: '#f05631' },
                "companyDetails": "中峪日用品交易市场均为中峪集团十大闭环产业之一，具有多行业背景和资源优势，整合融通了多品种商品资源、资产数字化运作、仓储物流、资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，帮助中小企业实现去库存、优化产能拉动内需...",
                "number": '400-185-3168',
                "cityCode": 330603,
                "label": {
                    "show": false
                },
                "engineer": "wenzhou_test"
            },
            {
                "id": 3,
                "name": "绍兴中峪职业技能培训学校有限公司",
                "value": [120.50114, 30.090647, 1],
                "adminCode": 330603,
                "city": "浙江省绍兴市柯桥区柯桥街道世界贸易中心（南区）16-0207室",
                "itemStyle": { color: '#ffc000', borderColor: '#ffc000' },
                "companyDetails": "中峪职业培训学校是一家结合国家职业教育体系改革指导政策，肩负起特色职业技能培育，为中峪产业培育人才，给社会输送专业人才，同时解决就业再就业问题，为地方职业教育发展提供助力，为养老和文化产业发展提供助力。",
                "cityCode": 330603,
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "shaoxing_test"
            },
        ],
        code: 200,
        message: 'ok',
    }
});
//新昌 330624
export const userDataxcx = Mock.mock(`/data/list?id=330624`, "get", (req) => {
    return {
        "list": [
            {
                "id": 'type',
                "name": "中峪道地药材平顶山仿野生白术种植基地",
                "value": [121.020489, 29.385847, 1],
                "adminCode": 330900,
                "itemStyle": { color: '#ff7300', borderColor: '#ff7300' },
                "type": 0,
                "city": "浙江新昌县沃洲镇黄坛村",
                "companyDetails": "新昌县沃洲镇黄坛村平顶山项目基地,隶属于浙江中峪道地药材有限公司绍兴市级区域范围内。成片种植浙八味之首白术,土地规模整村推进完整闭环100亩,一年生的道地白术种苗种植时间2023年12月至2024年2月,收成时间2025年10月至2026年1月。拟定生长周期为两年期,届时...",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "zhoushan_test"
            },
        ],
        code: 200,
        message: 'ok',
    }
});
//龙马潭区
export const userDatalmt = Mock.mock(`/data/list?id=510504`, "get", (req) => {
    return {
        "list": [
            {
                "id": 2,
                "name": "四川中峪拍卖交易服务有限责任公司",
                "value": [105.501628, 28.909885, 1],
                "adminCode": 510500,
                "city": "四川省泸州市龙马潭区自贸区川南临港片区临港大道二段10号",
                "itemStyle": { color: '#ff7300', borderColor: '#ff7300' },
                "companyDetails": "四川中峪拍卖交易服务有限责任公司简称“中峪数交”，为中峪集团生态内循环闭环产业链之一，具有多行业背景和资源优势，整合融通了多品种商品资源、数字化拍卖服务、仓储物流资源和资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合...",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "chengdu_test"
            },
        ],
        code: 200,
        message: 'ok',
    }
});
//叙永县
export const userDataxyx = Mock.mock(`/data/list?id=510524`, "get", (req) => {
    return {
        "list": [
            {
                "id": 4,
                "name": "丽水金生投资管理有限责任公司",
                "value": [105.688263, 27.862136, 1],
                "adminCode": 510500,
                "city": "四川省叙永县龙凤镇街村（原龙凤计划生育服务站）3层",
                "itemStyle": { color: '#f05631', borderColor: '#f05631' },
                "companyDetails": "投资管理公司不仅仅为各产业提供金融助力，更是为了集团进一步发展服务，在集团稳健发展的基础上，进行分层级的资本良性运作，为社会创造更多的财富，为共同富裕助力。",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "taizhou_test"
            },
            {
                "id": 5,
                "name": "四川中峪商贸服务有限责任公司",
                "value": [105.414307, 28.215201, 1],
                "adminCode": 510500,
                "itemStyle": { color: '#ffc000', borderColor: '#ffc000' },
                "companyDetails": "中峪商贸为四川省泸州市地方政府重点招商引资项目，是一家承载孵化落地集团全产业链使命的运营公司，以其独特商业运营架构和科学创新的市场培育体系，助推地方中小企业产品流通，促进地方区域经济发展，优化商品流通渠道。协同中峪拍交所为集团产业导入高粘度市场流...",

                "city": "四川省叙永县龙凤镇街村（原龙凤计划生育服务站）1层",
                "number": '400-185-3168',
                "label": {
                    "show": false
                },
                "engineer": "chengdu_test"
            },
        ],
        code: 200,
        message: 'ok',
    }
});